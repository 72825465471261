import { createIcon } from '@chakra-ui/react';

const PencilWithLineIcon = createIcon({
  displayName: 'PencilWithLineIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M14.2075 4.5856L11.4144 1.7931C11.3215 1.70021 11.2113 1.62653 11.0899 1.57626C10.9686 1.526 10.8385 1.50012 10.7072 1.50012C10.5759 1.50012 10.4458 1.526 10.3245 1.57626C10.2031 1.62653 10.0929 1.70021 10 1.7931L2.29313 9.49997C2.19987 9.59249 2.12593 9.70263 2.0756 9.82398C2.02528 9.94533 1.99959 10.0755 2.00001 10.2068V13C2.00001 13.2652 2.10536 13.5195 2.2929 13.7071C2.48043 13.8946 2.73479 14 3.00001 14H13.5C13.6326 14 13.7598 13.9473 13.8536 13.8535C13.9473 13.7598 14 13.6326 14 13.5C14 13.3674 13.9473 13.2402 13.8536 13.1464C13.7598 13.0526 13.6326 13 13.5 13H7.20751L14.2075 5.99997C14.3004 5.90711 14.3741 5.79686 14.4243 5.67552C14.4746 5.55418 14.5005 5.42412 14.5005 5.29278C14.5005 5.16144 14.4746 5.03139 14.4243 4.91005C14.3741 4.78871 14.3004 4.67846 14.2075 4.5856ZM5.79313 13H3.00001V10.2068L8.50001 4.70685L11.2931 7.49997L5.79313 13ZM12 6.7931L9.20751 3.99997L10.7075 2.49997L13.5 5.2931L12 6.7931Z"
      fill="#DCC8F0"
    />
  ),
});

export default PencilWithLineIcon;
