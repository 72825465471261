// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import StitchingMerger from "@graphql-mesh/merger-stitching";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { DecentTypes } from './sources/decent/types';
import type { SablierTypes } from './sources/sablier/types';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
  Timestamp: any;
};

export type Query = {
  dao?: Maybe<DAO>;
  daos: Array<DAO>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  action?: Maybe<Action>;
  actions: Array<Action>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  batch?: Maybe<Batch>;
  batches: Array<Batch>;
  batcher?: Maybe<Batcher>;
  batchers: Array<Batcher>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  segment?: Maybe<Segment>;
  segments: Array<Segment>;
  stream?: Maybe<Stream>;
  streams: Array<Stream>;
  tranche?: Maybe<Tranche>;
  tranches: Array<Tranche>;
  watcher?: Maybe<Watcher>;
  watchers: Array<Watcher>;
};


export type QuerydaoArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydaosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DAO_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DAO_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type QueryactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Action_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Action_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybatchArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybatchesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Batch_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Batch_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybatcherArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybatchersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Batcher_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Batcher_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycontractArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycontractsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Contract_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Contract_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysegmentArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysegmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Segment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Segment_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystreamArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytrancheArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytranchesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Tranche_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Tranche_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywatcherArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywatchersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Watcher_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Watcher_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Subscription = {
  dao?: Maybe<DAO>;
  daos: Array<DAO>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  action?: Maybe<Action>;
  actions: Array<Action>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  batch?: Maybe<Batch>;
  batches: Array<Batch>;
  batcher?: Maybe<Batcher>;
  batchers: Array<Batcher>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  segment?: Maybe<Segment>;
  segments: Array<Segment>;
  stream?: Maybe<Stream>;
  streams: Array<Stream>;
  tranche?: Maybe<Tranche>;
  tranches: Array<Tranche>;
  watcher?: Maybe<Watcher>;
  watchers: Array<Watcher>;
};


export type SubscriptiondaoArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondaosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DAO_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DAO_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type SubscriptionactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Action_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Action_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbatchArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbatchesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Batch_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Batch_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbatcherArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbatchersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Batcher_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Batcher_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncontractArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncontractsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Contract_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Contract_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsegmentArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsegmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Segment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Segment_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstreamArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontrancheArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontranchesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Tranche_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Tranche_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwatcherArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwatchersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Watcher_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Watcher_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Aggregation_interval =
  | 'hour'
  | 'day';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type DAO = {
  id: Scalars['Bytes'];
  address: Scalars['Bytes'];
  parentAddress?: Maybe<Scalars['Bytes']>;
  name?: Maybe<Scalars['String']>;
  hierarchy: Array<DAO>;
  proposalTemplatesHash?: Maybe<Scalars['String']>;
  snapshotENS?: Maybe<Scalars['String']>;
};


export type DAOhierarchyArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DAO_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DAO_filter>;
};

export type DAO_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  address?: InputMaybe<Scalars['Bytes']>;
  address_not?: InputMaybe<Scalars['Bytes']>;
  address_gt?: InputMaybe<Scalars['Bytes']>;
  address_lt?: InputMaybe<Scalars['Bytes']>;
  address_gte?: InputMaybe<Scalars['Bytes']>;
  address_lte?: InputMaybe<Scalars['Bytes']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_contains?: InputMaybe<Scalars['Bytes']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']>;
  parentAddress?: InputMaybe<Scalars['Bytes']>;
  parentAddress_not?: InputMaybe<Scalars['Bytes']>;
  parentAddress_gt?: InputMaybe<Scalars['Bytes']>;
  parentAddress_lt?: InputMaybe<Scalars['Bytes']>;
  parentAddress_gte?: InputMaybe<Scalars['Bytes']>;
  parentAddress_lte?: InputMaybe<Scalars['Bytes']>;
  parentAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  parentAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  parentAddress_contains?: InputMaybe<Scalars['Bytes']>;
  parentAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  hierarchy?: InputMaybe<Array<Scalars['String']>>;
  hierarchy_not?: InputMaybe<Array<Scalars['String']>>;
  hierarchy_contains?: InputMaybe<Array<Scalars['String']>>;
  hierarchy_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  hierarchy_not_contains?: InputMaybe<Array<Scalars['String']>>;
  hierarchy_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  hierarchy_?: InputMaybe<DAO_filter>;
  proposalTemplatesHash?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_gt?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_lt?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_gte?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_lte?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_in?: InputMaybe<Array<Scalars['String']>>;
  proposalTemplatesHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  proposalTemplatesHash_contains?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_contains_nocase?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not_contains?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_starts_with?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not_starts_with?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_ends_with?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not_ends_with?: InputMaybe<Scalars['String']>;
  proposalTemplatesHash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  snapshotENS?: InputMaybe<Scalars['String']>;
  snapshotENS_not?: InputMaybe<Scalars['String']>;
  snapshotENS_gt?: InputMaybe<Scalars['String']>;
  snapshotENS_lt?: InputMaybe<Scalars['String']>;
  snapshotENS_gte?: InputMaybe<Scalars['String']>;
  snapshotENS_lte?: InputMaybe<Scalars['String']>;
  snapshotENS_in?: InputMaybe<Array<Scalars['String']>>;
  snapshotENS_not_in?: InputMaybe<Array<Scalars['String']>>;
  snapshotENS_contains?: InputMaybe<Scalars['String']>;
  snapshotENS_contains_nocase?: InputMaybe<Scalars['String']>;
  snapshotENS_not_contains?: InputMaybe<Scalars['String']>;
  snapshotENS_not_contains_nocase?: InputMaybe<Scalars['String']>;
  snapshotENS_starts_with?: InputMaybe<Scalars['String']>;
  snapshotENS_starts_with_nocase?: InputMaybe<Scalars['String']>;
  snapshotENS_not_starts_with?: InputMaybe<Scalars['String']>;
  snapshotENS_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  snapshotENS_ends_with?: InputMaybe<Scalars['String']>;
  snapshotENS_ends_with_nocase?: InputMaybe<Scalars['String']>;
  snapshotENS_not_ends_with?: InputMaybe<Scalars['String']>;
  snapshotENS_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DAO_filter>>>;
  or?: InputMaybe<Array<InputMaybe<DAO_filter>>>;
};

export type DAO_orderBy =
  | 'id'
  | 'address'
  | 'parentAddress'
  | 'name'
  | 'hierarchy'
  | 'proposalTemplatesHash'
  | 'snapshotENS';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type Action = {
  /** unique identifier resolving to transaction hash concatenated with the log index (there may be multiple actions per tx) */
  id: Scalars['ID'];
  /** transaction details: block number */
  block: Scalars['BigInt'];
  /** category of action e.g. Deposit or Withdraw */
  category: ActionCategory;
  /** hardcoded chain id */
  chainId: Scalars['BigInt'];
  /** contract through which the stream actions has been triggered */
  contract: Contract;
  /** transaction details: hash */
  hash: Scalars['Bytes'];
  /** address that triggered the transaction */
  from: Scalars['Bytes'];
  /** stream linked to this action (or null if this is a contract level action) */
  stream?: Maybe<Stream>;
  /** unique global id tracked by the subgraph watcher */
  subgraphId: Scalars['BigInt'];
  /** transaction details: timestamp */
  timestamp: Scalars['BigInt'];
  /** address of an actor, differs based on action type e.g. for Create it resolves to the sender */
  addressA?: Maybe<Scalars['Bytes']>;
  /** address of an actor, differs based on action type e.g. for Transfer it resolves to the new recipient */
  addressB?: Maybe<Scalars['Bytes']>;
  /** amount, differs based on action type e.g. for Deposit it resolves to the depositAmount */
  amountA?: Maybe<Scalars['BigInt']>;
  /** amount, differs based on action type e.g. for Withdraw it resolves to the refundedAmount */
  amountB?: Maybe<Scalars['BigInt']>;
};

export type ActionCategory =
  | 'Approval'
  | 'ApprovalForAll'
  | 'Cancel'
  | 'Create'
  | 'Renounce'
  | 'Transfer'
  | 'Withdraw';

export type Action_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  category?: InputMaybe<ActionCategory>;
  category_not?: InputMaybe<ActionCategory>;
  category_in?: InputMaybe<Array<ActionCategory>>;
  category_not_in?: InputMaybe<Array<ActionCategory>>;
  chainId?: InputMaybe<Scalars['BigInt']>;
  chainId_not?: InputMaybe<Scalars['BigInt']>;
  chainId_gt?: InputMaybe<Scalars['BigInt']>;
  chainId_lt?: InputMaybe<Scalars['BigInt']>;
  chainId_gte?: InputMaybe<Scalars['BigInt']>;
  chainId_lte?: InputMaybe<Scalars['BigInt']>;
  chainId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  chainId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  contract?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_?: InputMaybe<Contract_filter>;
  hash?: InputMaybe<Scalars['Bytes']>;
  hash_not?: InputMaybe<Scalars['Bytes']>;
  hash_gt?: InputMaybe<Scalars['Bytes']>;
  hash_lt?: InputMaybe<Scalars['Bytes']>;
  hash_gte?: InputMaybe<Scalars['Bytes']>;
  hash_lte?: InputMaybe<Scalars['Bytes']>;
  hash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  hash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  hash_contains?: InputMaybe<Scalars['Bytes']>;
  hash_not_contains?: InputMaybe<Scalars['Bytes']>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  stream?: InputMaybe<Scalars['String']>;
  stream_not?: InputMaybe<Scalars['String']>;
  stream_gt?: InputMaybe<Scalars['String']>;
  stream_lt?: InputMaybe<Scalars['String']>;
  stream_gte?: InputMaybe<Scalars['String']>;
  stream_lte?: InputMaybe<Scalars['String']>;
  stream_in?: InputMaybe<Array<Scalars['String']>>;
  stream_not_in?: InputMaybe<Array<Scalars['String']>>;
  stream_contains?: InputMaybe<Scalars['String']>;
  stream_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_not_contains?: InputMaybe<Scalars['String']>;
  stream_not_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_starts_with?: InputMaybe<Scalars['String']>;
  stream_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_starts_with?: InputMaybe<Scalars['String']>;
  stream_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_ends_with?: InputMaybe<Scalars['String']>;
  stream_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_ends_with?: InputMaybe<Scalars['String']>;
  stream_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_?: InputMaybe<Stream_filter>;
  subgraphId?: InputMaybe<Scalars['BigInt']>;
  subgraphId_not?: InputMaybe<Scalars['BigInt']>;
  subgraphId_gt?: InputMaybe<Scalars['BigInt']>;
  subgraphId_lt?: InputMaybe<Scalars['BigInt']>;
  subgraphId_gte?: InputMaybe<Scalars['BigInt']>;
  subgraphId_lte?: InputMaybe<Scalars['BigInt']>;
  subgraphId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  subgraphId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  addressA?: InputMaybe<Scalars['Bytes']>;
  addressA_not?: InputMaybe<Scalars['Bytes']>;
  addressA_gt?: InputMaybe<Scalars['Bytes']>;
  addressA_lt?: InputMaybe<Scalars['Bytes']>;
  addressA_gte?: InputMaybe<Scalars['Bytes']>;
  addressA_lte?: InputMaybe<Scalars['Bytes']>;
  addressA_in?: InputMaybe<Array<Scalars['Bytes']>>;
  addressA_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  addressA_contains?: InputMaybe<Scalars['Bytes']>;
  addressA_not_contains?: InputMaybe<Scalars['Bytes']>;
  addressB?: InputMaybe<Scalars['Bytes']>;
  addressB_not?: InputMaybe<Scalars['Bytes']>;
  addressB_gt?: InputMaybe<Scalars['Bytes']>;
  addressB_lt?: InputMaybe<Scalars['Bytes']>;
  addressB_gte?: InputMaybe<Scalars['Bytes']>;
  addressB_lte?: InputMaybe<Scalars['Bytes']>;
  addressB_in?: InputMaybe<Array<Scalars['Bytes']>>;
  addressB_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  addressB_contains?: InputMaybe<Scalars['Bytes']>;
  addressB_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountA?: InputMaybe<Scalars['BigInt']>;
  amountA_not?: InputMaybe<Scalars['BigInt']>;
  amountA_gt?: InputMaybe<Scalars['BigInt']>;
  amountA_lt?: InputMaybe<Scalars['BigInt']>;
  amountA_gte?: InputMaybe<Scalars['BigInt']>;
  amountA_lte?: InputMaybe<Scalars['BigInt']>;
  amountA_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountA_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountB?: InputMaybe<Scalars['BigInt']>;
  amountB_not?: InputMaybe<Scalars['BigInt']>;
  amountB_gt?: InputMaybe<Scalars['BigInt']>;
  amountB_lt?: InputMaybe<Scalars['BigInt']>;
  amountB_gte?: InputMaybe<Scalars['BigInt']>;
  amountB_lte?: InputMaybe<Scalars['BigInt']>;
  amountB_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountB_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Action_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Action_filter>>>;
};

export type Action_orderBy =
  | 'id'
  | 'block'
  | 'category'
  | 'chainId'
  | 'contract'
  | 'contract__id'
  | 'contract__version'
  | 'contract__alias'
  | 'contract__address'
  | 'contract__admin'
  | 'contract__category'
  | 'hash'
  | 'from'
  | 'stream'
  | 'stream__id'
  | 'stream__alias'
  | 'stream__subgraphId'
  | 'stream__tokenId'
  | 'stream__version'
  | 'stream__category'
  | 'stream__chainId'
  | 'stream__hash'
  | 'stream__timestamp'
  | 'stream__funder'
  | 'stream__sender'
  | 'stream__recipient'
  | 'stream__proxender'
  | 'stream__proxied'
  | 'stream__cliff'
  | 'stream__cancelable'
  | 'stream__renounceTime'
  | 'stream__canceled'
  | 'stream__canceledTime'
  | 'stream__cliffTime'
  | 'stream__cliffAmount'
  | 'stream__endTime'
  | 'stream__startTime'
  | 'stream__duration'
  | 'stream__transferable'
  | 'stream__depositAmount'
  | 'stream__intactAmount'
  | 'stream__withdrawnAmount'
  | 'stream__position'
  | 'stream__brokerFeeAmount'
  | 'stream__protocolFeeAmount'
  | 'subgraphId'
  | 'timestamp'
  | 'addressA'
  | 'addressB'
  | 'amountA'
  | 'amountB';

export type Asset = {
  /** unique identifier resolving to the ERC20 asset/token address */
  id: Scalars['ID'];
  /** address of the ERC20 asset/token */
  address: Scalars['Bytes'];
  /** hardcoded chain id */
  chainId: Scalars['BigInt'];
  /** decimals of the ERC20 asset/token */
  decimals: Scalars['BigInt'];
  /** name of the ERC20 asset/token */
  name: Scalars['String'];
  /** symbol of the ERC20 asset/token */
  symbol: Scalars['String'];
  /** streams that rely on this asset/token */
  streams: Array<Stream>;
};


export type AssetstreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
};

export type Asset_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  address?: InputMaybe<Scalars['Bytes']>;
  address_not?: InputMaybe<Scalars['Bytes']>;
  address_gt?: InputMaybe<Scalars['Bytes']>;
  address_lt?: InputMaybe<Scalars['Bytes']>;
  address_gte?: InputMaybe<Scalars['Bytes']>;
  address_lte?: InputMaybe<Scalars['Bytes']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_contains?: InputMaybe<Scalars['Bytes']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']>;
  chainId?: InputMaybe<Scalars['BigInt']>;
  chainId_not?: InputMaybe<Scalars['BigInt']>;
  chainId_gt?: InputMaybe<Scalars['BigInt']>;
  chainId_lt?: InputMaybe<Scalars['BigInt']>;
  chainId_gte?: InputMaybe<Scalars['BigInt']>;
  chainId_lte?: InputMaybe<Scalars['BigInt']>;
  chainId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  chainId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  decimals?: InputMaybe<Scalars['BigInt']>;
  decimals_not?: InputMaybe<Scalars['BigInt']>;
  decimals_gt?: InputMaybe<Scalars['BigInt']>;
  decimals_lt?: InputMaybe<Scalars['BigInt']>;
  decimals_gte?: InputMaybe<Scalars['BigInt']>;
  decimals_lte?: InputMaybe<Scalars['BigInt']>;
  decimals_in?: InputMaybe<Array<Scalars['BigInt']>>;
  decimals_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  streams_?: InputMaybe<Stream_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Asset_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Asset_filter>>>;
};

export type Asset_orderBy =
  | 'id'
  | 'address'
  | 'chainId'
  | 'decimals'
  | 'name'
  | 'symbol'
  | 'streams';

export type Batch = {
  /** unique identifier of the batch, resolving to the hash of the parent transaction */
  id: Scalars['String'];
  /** number of streams that are part of this batch */
  size: Scalars['BigInt'];
  /** index of the batch resolving to a conditional counter of 2+ stream batches, filtered by sender (label is null for batches containing a single stream, for filtering) */
  label?: Maybe<Scalars['String']>;
  /** batcher (sender) that started this batch */
  batcher?: Maybe<Batcher>;
  /** streams that are part of this batch */
  streams: Array<Stream>;
  /** hash of the transaction that created this batch */
  hash: Scalars['Bytes'];
  /** timestamp of the transaction that created this batch */
  timestamp: Scalars['BigInt'];
};


export type BatchstreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
};

export type Batch_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['BigInt']>;
  size_not?: InputMaybe<Scalars['BigInt']>;
  size_gt?: InputMaybe<Scalars['BigInt']>;
  size_lt?: InputMaybe<Scalars['BigInt']>;
  size_gte?: InputMaybe<Scalars['BigInt']>;
  size_lte?: InputMaybe<Scalars['BigInt']>;
  size_in?: InputMaybe<Array<Scalars['BigInt']>>;
  size_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  label?: InputMaybe<Scalars['String']>;
  label_not?: InputMaybe<Scalars['String']>;
  label_gt?: InputMaybe<Scalars['String']>;
  label_lt?: InputMaybe<Scalars['String']>;
  label_gte?: InputMaybe<Scalars['String']>;
  label_lte?: InputMaybe<Scalars['String']>;
  label_in?: InputMaybe<Array<Scalars['String']>>;
  label_not_in?: InputMaybe<Array<Scalars['String']>>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_contains_nocase?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_contains_nocase?: InputMaybe<Scalars['String']>;
  label_starts_with?: InputMaybe<Scalars['String']>;
  label_starts_with_nocase?: InputMaybe<Scalars['String']>;
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  label_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  label_ends_with?: InputMaybe<Scalars['String']>;
  label_ends_with_nocase?: InputMaybe<Scalars['String']>;
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  label_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  batcher?: InputMaybe<Scalars['String']>;
  batcher_not?: InputMaybe<Scalars['String']>;
  batcher_gt?: InputMaybe<Scalars['String']>;
  batcher_lt?: InputMaybe<Scalars['String']>;
  batcher_gte?: InputMaybe<Scalars['String']>;
  batcher_lte?: InputMaybe<Scalars['String']>;
  batcher_in?: InputMaybe<Array<Scalars['String']>>;
  batcher_not_in?: InputMaybe<Array<Scalars['String']>>;
  batcher_contains?: InputMaybe<Scalars['String']>;
  batcher_contains_nocase?: InputMaybe<Scalars['String']>;
  batcher_not_contains?: InputMaybe<Scalars['String']>;
  batcher_not_contains_nocase?: InputMaybe<Scalars['String']>;
  batcher_starts_with?: InputMaybe<Scalars['String']>;
  batcher_starts_with_nocase?: InputMaybe<Scalars['String']>;
  batcher_not_starts_with?: InputMaybe<Scalars['String']>;
  batcher_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  batcher_ends_with?: InputMaybe<Scalars['String']>;
  batcher_ends_with_nocase?: InputMaybe<Scalars['String']>;
  batcher_not_ends_with?: InputMaybe<Scalars['String']>;
  batcher_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  batcher_?: InputMaybe<Batcher_filter>;
  streams_?: InputMaybe<Stream_filter>;
  hash?: InputMaybe<Scalars['Bytes']>;
  hash_not?: InputMaybe<Scalars['Bytes']>;
  hash_gt?: InputMaybe<Scalars['Bytes']>;
  hash_lt?: InputMaybe<Scalars['Bytes']>;
  hash_gte?: InputMaybe<Scalars['Bytes']>;
  hash_lte?: InputMaybe<Scalars['Bytes']>;
  hash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  hash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  hash_contains?: InputMaybe<Scalars['Bytes']>;
  hash_not_contains?: InputMaybe<Scalars['Bytes']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Batch_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Batch_filter>>>;
};

export type Batch_orderBy =
  | 'id'
  | 'size'
  | 'label'
  | 'batcher'
  | 'batcher__id'
  | 'batcher__address'
  | 'batcher__batchIndex'
  | 'streams'
  | 'hash'
  | 'timestamp';

export type Batcher = {
  /** unique identifier of the batcher (sender), resolving to their address */
  id: Scalars['String'];
  /** address of the sender */
  address: Scalars['Bytes'];
  /** numeric index, will be used to construct the batch label */
  batchIndex: Scalars['BigInt'];
  /** batches started by this batcher (sender) */
  batches: Array<Batch>;
};


export type BatcherbatchesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Batch_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Batch_filter>;
};

export type Batcher_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['Bytes']>;
  address_not?: InputMaybe<Scalars['Bytes']>;
  address_gt?: InputMaybe<Scalars['Bytes']>;
  address_lt?: InputMaybe<Scalars['Bytes']>;
  address_gte?: InputMaybe<Scalars['Bytes']>;
  address_lte?: InputMaybe<Scalars['Bytes']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_contains?: InputMaybe<Scalars['Bytes']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']>;
  batchIndex?: InputMaybe<Scalars['BigInt']>;
  batchIndex_not?: InputMaybe<Scalars['BigInt']>;
  batchIndex_gt?: InputMaybe<Scalars['BigInt']>;
  batchIndex_lt?: InputMaybe<Scalars['BigInt']>;
  batchIndex_gte?: InputMaybe<Scalars['BigInt']>;
  batchIndex_lte?: InputMaybe<Scalars['BigInt']>;
  batchIndex_in?: InputMaybe<Array<Scalars['BigInt']>>;
  batchIndex_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  batches_?: InputMaybe<Batch_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Batcher_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Batcher_filter>>>;
};

export type Batcher_orderBy =
  | 'id'
  | 'address'
  | 'batchIndex'
  | 'batches';

export type Contract = {
  /** unique identifier resolving to contract address */
  id: Scalars['String'];
  /** full version based on the v2-core contract versioning system (e.g. '2.0' / '2.1') */
  version: Scalars['String'];
  /** hardcoded alias, resolved by replacing the contract address from the id with the contract alias */
  alias: Scalars['String'];
  /** address of the contract */
  address: Scalars['Bytes'];
  /** address of the contract admin */
  admin?: Maybe<Scalars['Bytes']>;
  /** category of contract e.g. LockupLinear or LockupDynamic */
  category: ContractCategory;
  /** streams created through this contract */
  streams: Array<Stream>;
  /** actions on streams, triggered through this contract */
  actions: Array<Action>;
};


export type ContractstreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
};


export type ContractactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Action_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Action_filter>;
};

export type ContractCategory =
  | 'LockupDynamic'
  | 'LockupLinear'
  | 'LockupTranched';

export type Contract_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
  version_not?: InputMaybe<Scalars['String']>;
  version_gt?: InputMaybe<Scalars['String']>;
  version_lt?: InputMaybe<Scalars['String']>;
  version_gte?: InputMaybe<Scalars['String']>;
  version_lte?: InputMaybe<Scalars['String']>;
  version_in?: InputMaybe<Array<Scalars['String']>>;
  version_not_in?: InputMaybe<Array<Scalars['String']>>;
  version_contains?: InputMaybe<Scalars['String']>;
  version_contains_nocase?: InputMaybe<Scalars['String']>;
  version_not_contains?: InputMaybe<Scalars['String']>;
  version_not_contains_nocase?: InputMaybe<Scalars['String']>;
  version_starts_with?: InputMaybe<Scalars['String']>;
  version_starts_with_nocase?: InputMaybe<Scalars['String']>;
  version_not_starts_with?: InputMaybe<Scalars['String']>;
  version_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  version_ends_with?: InputMaybe<Scalars['String']>;
  version_ends_with_nocase?: InputMaybe<Scalars['String']>;
  version_not_ends_with?: InputMaybe<Scalars['String']>;
  version_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  alias_not?: InputMaybe<Scalars['String']>;
  alias_gt?: InputMaybe<Scalars['String']>;
  alias_lt?: InputMaybe<Scalars['String']>;
  alias_gte?: InputMaybe<Scalars['String']>;
  alias_lte?: InputMaybe<Scalars['String']>;
  alias_in?: InputMaybe<Array<Scalars['String']>>;
  alias_not_in?: InputMaybe<Array<Scalars['String']>>;
  alias_contains?: InputMaybe<Scalars['String']>;
  alias_contains_nocase?: InputMaybe<Scalars['String']>;
  alias_not_contains?: InputMaybe<Scalars['String']>;
  alias_not_contains_nocase?: InputMaybe<Scalars['String']>;
  alias_starts_with?: InputMaybe<Scalars['String']>;
  alias_starts_with_nocase?: InputMaybe<Scalars['String']>;
  alias_not_starts_with?: InputMaybe<Scalars['String']>;
  alias_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  alias_ends_with?: InputMaybe<Scalars['String']>;
  alias_ends_with_nocase?: InputMaybe<Scalars['String']>;
  alias_not_ends_with?: InputMaybe<Scalars['String']>;
  alias_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['Bytes']>;
  address_not?: InputMaybe<Scalars['Bytes']>;
  address_gt?: InputMaybe<Scalars['Bytes']>;
  address_lt?: InputMaybe<Scalars['Bytes']>;
  address_gte?: InputMaybe<Scalars['Bytes']>;
  address_lte?: InputMaybe<Scalars['Bytes']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  address_contains?: InputMaybe<Scalars['Bytes']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']>;
  admin?: InputMaybe<Scalars['Bytes']>;
  admin_not?: InputMaybe<Scalars['Bytes']>;
  admin_gt?: InputMaybe<Scalars['Bytes']>;
  admin_lt?: InputMaybe<Scalars['Bytes']>;
  admin_gte?: InputMaybe<Scalars['Bytes']>;
  admin_lte?: InputMaybe<Scalars['Bytes']>;
  admin_in?: InputMaybe<Array<Scalars['Bytes']>>;
  admin_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  admin_contains?: InputMaybe<Scalars['Bytes']>;
  admin_not_contains?: InputMaybe<Scalars['Bytes']>;
  category?: InputMaybe<ContractCategory>;
  category_not?: InputMaybe<ContractCategory>;
  category_in?: InputMaybe<Array<ContractCategory>>;
  category_not_in?: InputMaybe<Array<ContractCategory>>;
  streams_?: InputMaybe<Stream_filter>;
  actions_?: InputMaybe<Action_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Contract_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Contract_filter>>>;
};

export type Contract_orderBy =
  | 'id'
  | 'version'
  | 'alias'
  | 'address'
  | 'admin'
  | 'category'
  | 'streams'
  | 'actions';

export type Segment = {
  /** unique id resolving to stream id concatenated with the position of the segment */
  id: Scalars['String'];
  /** position of the segment inside the array */
  position: Scalars['BigInt'];
  /** stream upon which the segment is used */
  stream: Stream;
  /** amount resolving to the value streamed during this segment */
  amount: Scalars['BigInt'];
  /** exponent for the time passed percentage of the main amount compute function */
  exponent: Scalars['BigInt'];
  /** timestamp resolving to the end moment of the segment */
  milestone: Scalars['BigInt'];
  /** timestamp resolving to the *milestone* parameter */
  endTime: Scalars['BigInt'];
  /** timestamp resolving to the start moment of the segment (the end time of previous segment or the startTime of the stream) */
  startTime: Scalars['BigInt'];
  /** derived amount resoling to the total amount streamed until the start of the segment */
  startAmount: Scalars['BigInt'];
  /** derived amount resoling to the total amount streamed until the end of the segment */
  endAmount: Scalars['BigInt'];
};

export type Segment_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['BigInt']>;
  position_not?: InputMaybe<Scalars['BigInt']>;
  position_gt?: InputMaybe<Scalars['BigInt']>;
  position_lt?: InputMaybe<Scalars['BigInt']>;
  position_gte?: InputMaybe<Scalars['BigInt']>;
  position_lte?: InputMaybe<Scalars['BigInt']>;
  position_in?: InputMaybe<Array<Scalars['BigInt']>>;
  position_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stream?: InputMaybe<Scalars['String']>;
  stream_not?: InputMaybe<Scalars['String']>;
  stream_gt?: InputMaybe<Scalars['String']>;
  stream_lt?: InputMaybe<Scalars['String']>;
  stream_gte?: InputMaybe<Scalars['String']>;
  stream_lte?: InputMaybe<Scalars['String']>;
  stream_in?: InputMaybe<Array<Scalars['String']>>;
  stream_not_in?: InputMaybe<Array<Scalars['String']>>;
  stream_contains?: InputMaybe<Scalars['String']>;
  stream_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_not_contains?: InputMaybe<Scalars['String']>;
  stream_not_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_starts_with?: InputMaybe<Scalars['String']>;
  stream_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_starts_with?: InputMaybe<Scalars['String']>;
  stream_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_ends_with?: InputMaybe<Scalars['String']>;
  stream_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_ends_with?: InputMaybe<Scalars['String']>;
  stream_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_?: InputMaybe<Stream_filter>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  exponent?: InputMaybe<Scalars['BigInt']>;
  exponent_not?: InputMaybe<Scalars['BigInt']>;
  exponent_gt?: InputMaybe<Scalars['BigInt']>;
  exponent_lt?: InputMaybe<Scalars['BigInt']>;
  exponent_gte?: InputMaybe<Scalars['BigInt']>;
  exponent_lte?: InputMaybe<Scalars['BigInt']>;
  exponent_in?: InputMaybe<Array<Scalars['BigInt']>>;
  exponent_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  milestone?: InputMaybe<Scalars['BigInt']>;
  milestone_not?: InputMaybe<Scalars['BigInt']>;
  milestone_gt?: InputMaybe<Scalars['BigInt']>;
  milestone_lt?: InputMaybe<Scalars['BigInt']>;
  milestone_gte?: InputMaybe<Scalars['BigInt']>;
  milestone_lte?: InputMaybe<Scalars['BigInt']>;
  milestone_in?: InputMaybe<Array<Scalars['BigInt']>>;
  milestone_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime?: InputMaybe<Scalars['BigInt']>;
  endTime_not?: InputMaybe<Scalars['BigInt']>;
  endTime_gt?: InputMaybe<Scalars['BigInt']>;
  endTime_lt?: InputMaybe<Scalars['BigInt']>;
  endTime_gte?: InputMaybe<Scalars['BigInt']>;
  endTime_lte?: InputMaybe<Scalars['BigInt']>;
  endTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime?: InputMaybe<Scalars['BigInt']>;
  startTime_not?: InputMaybe<Scalars['BigInt']>;
  startTime_gt?: InputMaybe<Scalars['BigInt']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']>;
  startTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startAmount?: InputMaybe<Scalars['BigInt']>;
  startAmount_not?: InputMaybe<Scalars['BigInt']>;
  startAmount_gt?: InputMaybe<Scalars['BigInt']>;
  startAmount_lt?: InputMaybe<Scalars['BigInt']>;
  startAmount_gte?: InputMaybe<Scalars['BigInt']>;
  startAmount_lte?: InputMaybe<Scalars['BigInt']>;
  startAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endAmount?: InputMaybe<Scalars['BigInt']>;
  endAmount_not?: InputMaybe<Scalars['BigInt']>;
  endAmount_gt?: InputMaybe<Scalars['BigInt']>;
  endAmount_lt?: InputMaybe<Scalars['BigInt']>;
  endAmount_gte?: InputMaybe<Scalars['BigInt']>;
  endAmount_lte?: InputMaybe<Scalars['BigInt']>;
  endAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Segment_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Segment_filter>>>;
};

export type Segment_orderBy =
  | 'id'
  | 'position'
  | 'stream'
  | 'stream__id'
  | 'stream__alias'
  | 'stream__subgraphId'
  | 'stream__tokenId'
  | 'stream__version'
  | 'stream__category'
  | 'stream__chainId'
  | 'stream__hash'
  | 'stream__timestamp'
  | 'stream__funder'
  | 'stream__sender'
  | 'stream__recipient'
  | 'stream__proxender'
  | 'stream__proxied'
  | 'stream__cliff'
  | 'stream__cancelable'
  | 'stream__renounceTime'
  | 'stream__canceled'
  | 'stream__canceledTime'
  | 'stream__cliffTime'
  | 'stream__cliffAmount'
  | 'stream__endTime'
  | 'stream__startTime'
  | 'stream__duration'
  | 'stream__transferable'
  | 'stream__depositAmount'
  | 'stream__intactAmount'
  | 'stream__withdrawnAmount'
  | 'stream__position'
  | 'stream__brokerFeeAmount'
  | 'stream__protocolFeeAmount'
  | 'amount'
  | 'exponent'
  | 'milestone'
  | 'endTime'
  | 'startTime'
  | 'startAmount'
  | 'endAmount';

export type Stream = {
  /** unique identifier for the stream, resolving to the origin contract address concatenated with the chainId and the tokenId */
  id: Scalars['String'];
  /** hardcoded alias, resolved by replacing the contract address from the id with the contract alias */
  alias: Scalars['String'];
  /** unique global id tracked by the subgraph watcher - ኆ80 these may change if new contracts are added and the chronological order of streams changes */
  subgraphId: Scalars['BigInt'];
  /** unique identifier for the stream released by the origin contract - same as the tokenId or streamId values given by the contract */
  tokenId: Scalars['BigInt'];
  /** full version based on the v2-core contract versioning system (e.g. '2.0' / '2.1') */
  version: Scalars['String'];
  /** type of stream (for sorting reasons) */
  category: StreamCategory;
  /** hardcoded chain id */
  chainId: Scalars['BigInt'];
  /** contract through which this stream has been created */
  contract: Contract;
  /** transaction hash for the stream creation */
  hash: Scalars['Bytes'];
  /** timestamp of the stream creation (for sorting reasons) */
  timestamp: Scalars['BigInt'];
  /** source of funds for the stream (anyone can create and fund a stream on any sender's behalf) */
  funder: Scalars['Bytes'];
  /** manager of the stream, defined at creation by the funder (usually the same as the funder) */
  sender: Scalars['Bytes'];
  /** current recipient of the stream, can withdraw the funds (the recipient can change on stream/nft transfer) */
  recipient: Scalars['Bytes'];
  /** list of relevant stream parties (for filtering) - sender, recipient, possibly sender's */
  parties: Array<Scalars['Bytes']>;
  /** owner of the proxy when the stream is created through a PRBProxy (sender = proxy, proxender = owner(proxy)) */
  proxender?: Maybe<Scalars['Bytes']>;
  /** flag for streams created through a proxy */
  proxied: Scalars['Boolean'];
  /** flag for linear streams with a cliff */
  cliff: Scalars['Boolean'];
  /** asset (ERC20 token) supported by this stream */
  asset: Asset;
  /** flag showing the cancelability of the stream (making it false is a one-way trip) */
  cancelable: Scalars['Boolean'];
  /** action in which the stream has been made non-cancelable (can be the deposit transaction or a different one) */
  renounceAction?: Maybe<Action>;
  /** timestamp for the when the stream was made non-cancelable */
  renounceTime?: Maybe<Scalars['BigInt']>;
  /** flag showing if the stream was (making it true is a one-way trip) */
  canceled: Scalars['Boolean'];
  /** action in which the stream was */
  canceledAction?: Maybe<Action>;
  /** timestamp for the when the stream was canceled */
  canceledTime?: Maybe<Scalars['BigInt']>;
  /** timestamp for the start of the cliff, only available on linear streams */
  cliffTime?: Maybe<Scalars['BigInt']>;
  /** derived amount of the cliff, only available on linear streams */
  cliffAmount?: Maybe<Scalars['BigInt']>;
  /** timestamp for the end of the stream */
  endTime: Scalars['BigInt'];
  /** timestamp for the start of the stream */
  startTime: Scalars['BigInt'];
  /** snapshot of the duration (difference between end and start time) */
  duration: Scalars['BigInt'];
  /** flag showing the transferability of the stream (decided when the stream is created) */
  transferable: Scalars['Boolean'];
  /** amount deposit in the stream (without fees) */
  depositAmount: Scalars['BigInt'];
  /** amount resolving to the sum still inside the stream (regardless of it being locked or not) (difference between deposit and withdrawn pre-cancel) */
  intactAmount: Scalars['BigInt'];
  /** amount resolving to the sum of all withdrawals */
  withdrawnAmount: Scalars['BigInt'];
  /** actions triggered in the context of this stream */
  actions: Array<Action>;
  /** batch the stream is part of, only available when created within a batch create transaction */
  batch: Batch;
  /** position in the batch, only available when created within a batch create transaction */
  position: Scalars['BigInt'];
  /** segments of the stream curve, only available on dynamic streams */
  segments: Array<Segment>;
  /** segments of the stream curve, only available on dynamic streams */
  tranches: Array<Tranche>;
  /** amount of fees paid to the broker */
  brokerFeeAmount: Scalars['BigInt'];
  /** amount of fees paid to the protocol */
  protocolFeeAmount: Scalars['BigInt'];
};


export type StreamactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Action_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Action_filter>;
};


export type StreamsegmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Segment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Segment_filter>;
};


export type StreamtranchesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Tranche_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Tranche_filter>;
};

export type StreamCategory =
  | 'LockupDynamic'
  | 'LockupLinear'
  | 'LockupTranched';

export type Stream_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  alias_not?: InputMaybe<Scalars['String']>;
  alias_gt?: InputMaybe<Scalars['String']>;
  alias_lt?: InputMaybe<Scalars['String']>;
  alias_gte?: InputMaybe<Scalars['String']>;
  alias_lte?: InputMaybe<Scalars['String']>;
  alias_in?: InputMaybe<Array<Scalars['String']>>;
  alias_not_in?: InputMaybe<Array<Scalars['String']>>;
  alias_contains?: InputMaybe<Scalars['String']>;
  alias_contains_nocase?: InputMaybe<Scalars['String']>;
  alias_not_contains?: InputMaybe<Scalars['String']>;
  alias_not_contains_nocase?: InputMaybe<Scalars['String']>;
  alias_starts_with?: InputMaybe<Scalars['String']>;
  alias_starts_with_nocase?: InputMaybe<Scalars['String']>;
  alias_not_starts_with?: InputMaybe<Scalars['String']>;
  alias_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  alias_ends_with?: InputMaybe<Scalars['String']>;
  alias_ends_with_nocase?: InputMaybe<Scalars['String']>;
  alias_not_ends_with?: InputMaybe<Scalars['String']>;
  alias_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  subgraphId?: InputMaybe<Scalars['BigInt']>;
  subgraphId_not?: InputMaybe<Scalars['BigInt']>;
  subgraphId_gt?: InputMaybe<Scalars['BigInt']>;
  subgraphId_lt?: InputMaybe<Scalars['BigInt']>;
  subgraphId_gte?: InputMaybe<Scalars['BigInt']>;
  subgraphId_lte?: InputMaybe<Scalars['BigInt']>;
  subgraphId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  subgraphId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  version?: InputMaybe<Scalars['String']>;
  version_not?: InputMaybe<Scalars['String']>;
  version_gt?: InputMaybe<Scalars['String']>;
  version_lt?: InputMaybe<Scalars['String']>;
  version_gte?: InputMaybe<Scalars['String']>;
  version_lte?: InputMaybe<Scalars['String']>;
  version_in?: InputMaybe<Array<Scalars['String']>>;
  version_not_in?: InputMaybe<Array<Scalars['String']>>;
  version_contains?: InputMaybe<Scalars['String']>;
  version_contains_nocase?: InputMaybe<Scalars['String']>;
  version_not_contains?: InputMaybe<Scalars['String']>;
  version_not_contains_nocase?: InputMaybe<Scalars['String']>;
  version_starts_with?: InputMaybe<Scalars['String']>;
  version_starts_with_nocase?: InputMaybe<Scalars['String']>;
  version_not_starts_with?: InputMaybe<Scalars['String']>;
  version_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  version_ends_with?: InputMaybe<Scalars['String']>;
  version_ends_with_nocase?: InputMaybe<Scalars['String']>;
  version_not_ends_with?: InputMaybe<Scalars['String']>;
  version_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<StreamCategory>;
  category_not?: InputMaybe<StreamCategory>;
  category_in?: InputMaybe<Array<StreamCategory>>;
  category_not_in?: InputMaybe<Array<StreamCategory>>;
  chainId?: InputMaybe<Scalars['BigInt']>;
  chainId_not?: InputMaybe<Scalars['BigInt']>;
  chainId_gt?: InputMaybe<Scalars['BigInt']>;
  chainId_lt?: InputMaybe<Scalars['BigInt']>;
  chainId_gte?: InputMaybe<Scalars['BigInt']>;
  chainId_lte?: InputMaybe<Scalars['BigInt']>;
  chainId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  chainId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  contract?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_?: InputMaybe<Contract_filter>;
  hash?: InputMaybe<Scalars['Bytes']>;
  hash_not?: InputMaybe<Scalars['Bytes']>;
  hash_gt?: InputMaybe<Scalars['Bytes']>;
  hash_lt?: InputMaybe<Scalars['Bytes']>;
  hash_gte?: InputMaybe<Scalars['Bytes']>;
  hash_lte?: InputMaybe<Scalars['Bytes']>;
  hash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  hash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  hash_contains?: InputMaybe<Scalars['Bytes']>;
  hash_not_contains?: InputMaybe<Scalars['Bytes']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  funder?: InputMaybe<Scalars['Bytes']>;
  funder_not?: InputMaybe<Scalars['Bytes']>;
  funder_gt?: InputMaybe<Scalars['Bytes']>;
  funder_lt?: InputMaybe<Scalars['Bytes']>;
  funder_gte?: InputMaybe<Scalars['Bytes']>;
  funder_lte?: InputMaybe<Scalars['Bytes']>;
  funder_in?: InputMaybe<Array<Scalars['Bytes']>>;
  funder_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  funder_contains?: InputMaybe<Scalars['Bytes']>;
  funder_not_contains?: InputMaybe<Scalars['Bytes']>;
  sender?: InputMaybe<Scalars['Bytes']>;
  sender_not?: InputMaybe<Scalars['Bytes']>;
  sender_gt?: InputMaybe<Scalars['Bytes']>;
  sender_lt?: InputMaybe<Scalars['Bytes']>;
  sender_gte?: InputMaybe<Scalars['Bytes']>;
  sender_lte?: InputMaybe<Scalars['Bytes']>;
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>;
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  sender_contains?: InputMaybe<Scalars['Bytes']>;
  sender_not_contains?: InputMaybe<Scalars['Bytes']>;
  recipient?: InputMaybe<Scalars['Bytes']>;
  recipient_not?: InputMaybe<Scalars['Bytes']>;
  recipient_gt?: InputMaybe<Scalars['Bytes']>;
  recipient_lt?: InputMaybe<Scalars['Bytes']>;
  recipient_gte?: InputMaybe<Scalars['Bytes']>;
  recipient_lte?: InputMaybe<Scalars['Bytes']>;
  recipient_in?: InputMaybe<Array<Scalars['Bytes']>>;
  recipient_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  recipient_contains?: InputMaybe<Scalars['Bytes']>;
  recipient_not_contains?: InputMaybe<Scalars['Bytes']>;
  parties?: InputMaybe<Array<Scalars['Bytes']>>;
  parties_not?: InputMaybe<Array<Scalars['Bytes']>>;
  parties_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  parties_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  parties_not_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  parties_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  proxender?: InputMaybe<Scalars['Bytes']>;
  proxender_not?: InputMaybe<Scalars['Bytes']>;
  proxender_gt?: InputMaybe<Scalars['Bytes']>;
  proxender_lt?: InputMaybe<Scalars['Bytes']>;
  proxender_gte?: InputMaybe<Scalars['Bytes']>;
  proxender_lte?: InputMaybe<Scalars['Bytes']>;
  proxender_in?: InputMaybe<Array<Scalars['Bytes']>>;
  proxender_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  proxender_contains?: InputMaybe<Scalars['Bytes']>;
  proxender_not_contains?: InputMaybe<Scalars['Bytes']>;
  proxied?: InputMaybe<Scalars['Boolean']>;
  proxied_not?: InputMaybe<Scalars['Boolean']>;
  proxied_in?: InputMaybe<Array<Scalars['Boolean']>>;
  proxied_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  cliff?: InputMaybe<Scalars['Boolean']>;
  cliff_not?: InputMaybe<Scalars['Boolean']>;
  cliff_in?: InputMaybe<Array<Scalars['Boolean']>>;
  cliff_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  asset?: InputMaybe<Scalars['String']>;
  asset_not?: InputMaybe<Scalars['String']>;
  asset_gt?: InputMaybe<Scalars['String']>;
  asset_lt?: InputMaybe<Scalars['String']>;
  asset_gte?: InputMaybe<Scalars['String']>;
  asset_lte?: InputMaybe<Scalars['String']>;
  asset_in?: InputMaybe<Array<Scalars['String']>>;
  asset_not_in?: InputMaybe<Array<Scalars['String']>>;
  asset_contains?: InputMaybe<Scalars['String']>;
  asset_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_not_contains?: InputMaybe<Scalars['String']>;
  asset_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_starts_with?: InputMaybe<Scalars['String']>;
  asset_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_starts_with?: InputMaybe<Scalars['String']>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_ends_with?: InputMaybe<Scalars['String']>;
  asset_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_ends_with?: InputMaybe<Scalars['String']>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_?: InputMaybe<Asset_filter>;
  cancelable?: InputMaybe<Scalars['Boolean']>;
  cancelable_not?: InputMaybe<Scalars['Boolean']>;
  cancelable_in?: InputMaybe<Array<Scalars['Boolean']>>;
  cancelable_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  renounceAction?: InputMaybe<Scalars['String']>;
  renounceAction_not?: InputMaybe<Scalars['String']>;
  renounceAction_gt?: InputMaybe<Scalars['String']>;
  renounceAction_lt?: InputMaybe<Scalars['String']>;
  renounceAction_gte?: InputMaybe<Scalars['String']>;
  renounceAction_lte?: InputMaybe<Scalars['String']>;
  renounceAction_in?: InputMaybe<Array<Scalars['String']>>;
  renounceAction_not_in?: InputMaybe<Array<Scalars['String']>>;
  renounceAction_contains?: InputMaybe<Scalars['String']>;
  renounceAction_contains_nocase?: InputMaybe<Scalars['String']>;
  renounceAction_not_contains?: InputMaybe<Scalars['String']>;
  renounceAction_not_contains_nocase?: InputMaybe<Scalars['String']>;
  renounceAction_starts_with?: InputMaybe<Scalars['String']>;
  renounceAction_starts_with_nocase?: InputMaybe<Scalars['String']>;
  renounceAction_not_starts_with?: InputMaybe<Scalars['String']>;
  renounceAction_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  renounceAction_ends_with?: InputMaybe<Scalars['String']>;
  renounceAction_ends_with_nocase?: InputMaybe<Scalars['String']>;
  renounceAction_not_ends_with?: InputMaybe<Scalars['String']>;
  renounceAction_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  renounceAction_?: InputMaybe<Action_filter>;
  renounceTime?: InputMaybe<Scalars['BigInt']>;
  renounceTime_not?: InputMaybe<Scalars['BigInt']>;
  renounceTime_gt?: InputMaybe<Scalars['BigInt']>;
  renounceTime_lt?: InputMaybe<Scalars['BigInt']>;
  renounceTime_gte?: InputMaybe<Scalars['BigInt']>;
  renounceTime_lte?: InputMaybe<Scalars['BigInt']>;
  renounceTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  renounceTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  canceled?: InputMaybe<Scalars['Boolean']>;
  canceled_not?: InputMaybe<Scalars['Boolean']>;
  canceled_in?: InputMaybe<Array<Scalars['Boolean']>>;
  canceled_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  canceledAction?: InputMaybe<Scalars['String']>;
  canceledAction_not?: InputMaybe<Scalars['String']>;
  canceledAction_gt?: InputMaybe<Scalars['String']>;
  canceledAction_lt?: InputMaybe<Scalars['String']>;
  canceledAction_gte?: InputMaybe<Scalars['String']>;
  canceledAction_lte?: InputMaybe<Scalars['String']>;
  canceledAction_in?: InputMaybe<Array<Scalars['String']>>;
  canceledAction_not_in?: InputMaybe<Array<Scalars['String']>>;
  canceledAction_contains?: InputMaybe<Scalars['String']>;
  canceledAction_contains_nocase?: InputMaybe<Scalars['String']>;
  canceledAction_not_contains?: InputMaybe<Scalars['String']>;
  canceledAction_not_contains_nocase?: InputMaybe<Scalars['String']>;
  canceledAction_starts_with?: InputMaybe<Scalars['String']>;
  canceledAction_starts_with_nocase?: InputMaybe<Scalars['String']>;
  canceledAction_not_starts_with?: InputMaybe<Scalars['String']>;
  canceledAction_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  canceledAction_ends_with?: InputMaybe<Scalars['String']>;
  canceledAction_ends_with_nocase?: InputMaybe<Scalars['String']>;
  canceledAction_not_ends_with?: InputMaybe<Scalars['String']>;
  canceledAction_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  canceledAction_?: InputMaybe<Action_filter>;
  canceledTime?: InputMaybe<Scalars['BigInt']>;
  canceledTime_not?: InputMaybe<Scalars['BigInt']>;
  canceledTime_gt?: InputMaybe<Scalars['BigInt']>;
  canceledTime_lt?: InputMaybe<Scalars['BigInt']>;
  canceledTime_gte?: InputMaybe<Scalars['BigInt']>;
  canceledTime_lte?: InputMaybe<Scalars['BigInt']>;
  canceledTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  canceledTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffTime?: InputMaybe<Scalars['BigInt']>;
  cliffTime_not?: InputMaybe<Scalars['BigInt']>;
  cliffTime_gt?: InputMaybe<Scalars['BigInt']>;
  cliffTime_lt?: InputMaybe<Scalars['BigInt']>;
  cliffTime_gte?: InputMaybe<Scalars['BigInt']>;
  cliffTime_lte?: InputMaybe<Scalars['BigInt']>;
  cliffTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffAmount?: InputMaybe<Scalars['BigInt']>;
  cliffAmount_not?: InputMaybe<Scalars['BigInt']>;
  cliffAmount_gt?: InputMaybe<Scalars['BigInt']>;
  cliffAmount_lt?: InputMaybe<Scalars['BigInt']>;
  cliffAmount_gte?: InputMaybe<Scalars['BigInt']>;
  cliffAmount_lte?: InputMaybe<Scalars['BigInt']>;
  cliffAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime?: InputMaybe<Scalars['BigInt']>;
  endTime_not?: InputMaybe<Scalars['BigInt']>;
  endTime_gt?: InputMaybe<Scalars['BigInt']>;
  endTime_lt?: InputMaybe<Scalars['BigInt']>;
  endTime_gte?: InputMaybe<Scalars['BigInt']>;
  endTime_lte?: InputMaybe<Scalars['BigInt']>;
  endTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime?: InputMaybe<Scalars['BigInt']>;
  startTime_not?: InputMaybe<Scalars['BigInt']>;
  startTime_gt?: InputMaybe<Scalars['BigInt']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']>;
  startTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration?: InputMaybe<Scalars['BigInt']>;
  duration_not?: InputMaybe<Scalars['BigInt']>;
  duration_gt?: InputMaybe<Scalars['BigInt']>;
  duration_lt?: InputMaybe<Scalars['BigInt']>;
  duration_gte?: InputMaybe<Scalars['BigInt']>;
  duration_lte?: InputMaybe<Scalars['BigInt']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transferable?: InputMaybe<Scalars['Boolean']>;
  transferable_not?: InputMaybe<Scalars['Boolean']>;
  transferable_in?: InputMaybe<Array<Scalars['Boolean']>>;
  transferable_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  depositAmount?: InputMaybe<Scalars['BigInt']>;
  depositAmount_not?: InputMaybe<Scalars['BigInt']>;
  depositAmount_gt?: InputMaybe<Scalars['BigInt']>;
  depositAmount_lt?: InputMaybe<Scalars['BigInt']>;
  depositAmount_gte?: InputMaybe<Scalars['BigInt']>;
  depositAmount_lte?: InputMaybe<Scalars['BigInt']>;
  depositAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  depositAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  intactAmount?: InputMaybe<Scalars['BigInt']>;
  intactAmount_not?: InputMaybe<Scalars['BigInt']>;
  intactAmount_gt?: InputMaybe<Scalars['BigInt']>;
  intactAmount_lt?: InputMaybe<Scalars['BigInt']>;
  intactAmount_gte?: InputMaybe<Scalars['BigInt']>;
  intactAmount_lte?: InputMaybe<Scalars['BigInt']>;
  intactAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  intactAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmount?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_not?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_gt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_lt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_gte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_lte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  actions_?: InputMaybe<Action_filter>;
  batch?: InputMaybe<Scalars['String']>;
  batch_not?: InputMaybe<Scalars['String']>;
  batch_gt?: InputMaybe<Scalars['String']>;
  batch_lt?: InputMaybe<Scalars['String']>;
  batch_gte?: InputMaybe<Scalars['String']>;
  batch_lte?: InputMaybe<Scalars['String']>;
  batch_in?: InputMaybe<Array<Scalars['String']>>;
  batch_not_in?: InputMaybe<Array<Scalars['String']>>;
  batch_contains?: InputMaybe<Scalars['String']>;
  batch_contains_nocase?: InputMaybe<Scalars['String']>;
  batch_not_contains?: InputMaybe<Scalars['String']>;
  batch_not_contains_nocase?: InputMaybe<Scalars['String']>;
  batch_starts_with?: InputMaybe<Scalars['String']>;
  batch_starts_with_nocase?: InputMaybe<Scalars['String']>;
  batch_not_starts_with?: InputMaybe<Scalars['String']>;
  batch_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  batch_ends_with?: InputMaybe<Scalars['String']>;
  batch_ends_with_nocase?: InputMaybe<Scalars['String']>;
  batch_not_ends_with?: InputMaybe<Scalars['String']>;
  batch_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  batch_?: InputMaybe<Batch_filter>;
  position?: InputMaybe<Scalars['BigInt']>;
  position_not?: InputMaybe<Scalars['BigInt']>;
  position_gt?: InputMaybe<Scalars['BigInt']>;
  position_lt?: InputMaybe<Scalars['BigInt']>;
  position_gte?: InputMaybe<Scalars['BigInt']>;
  position_lte?: InputMaybe<Scalars['BigInt']>;
  position_in?: InputMaybe<Array<Scalars['BigInt']>>;
  position_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  segments_?: InputMaybe<Segment_filter>;
  tranches_?: InputMaybe<Tranche_filter>;
  brokerFeeAmount?: InputMaybe<Scalars['BigInt']>;
  brokerFeeAmount_not?: InputMaybe<Scalars['BigInt']>;
  brokerFeeAmount_gt?: InputMaybe<Scalars['BigInt']>;
  brokerFeeAmount_lt?: InputMaybe<Scalars['BigInt']>;
  brokerFeeAmount_gte?: InputMaybe<Scalars['BigInt']>;
  brokerFeeAmount_lte?: InputMaybe<Scalars['BigInt']>;
  brokerFeeAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  brokerFeeAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  protocolFeeAmount?: InputMaybe<Scalars['BigInt']>;
  protocolFeeAmount_not?: InputMaybe<Scalars['BigInt']>;
  protocolFeeAmount_gt?: InputMaybe<Scalars['BigInt']>;
  protocolFeeAmount_lt?: InputMaybe<Scalars['BigInt']>;
  protocolFeeAmount_gte?: InputMaybe<Scalars['BigInt']>;
  protocolFeeAmount_lte?: InputMaybe<Scalars['BigInt']>;
  protocolFeeAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  protocolFeeAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Stream_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Stream_filter>>>;
};

export type Stream_orderBy =
  | 'id'
  | 'alias'
  | 'subgraphId'
  | 'tokenId'
  | 'version'
  | 'category'
  | 'chainId'
  | 'contract'
  | 'contract__id'
  | 'contract__version'
  | 'contract__alias'
  | 'contract__address'
  | 'contract__admin'
  | 'contract__category'
  | 'hash'
  | 'timestamp'
  | 'funder'
  | 'sender'
  | 'recipient'
  | 'parties'
  | 'proxender'
  | 'proxied'
  | 'cliff'
  | 'asset'
  | 'asset__id'
  | 'asset__address'
  | 'asset__chainId'
  | 'asset__decimals'
  | 'asset__name'
  | 'asset__symbol'
  | 'cancelable'
  | 'renounceAction'
  | 'renounceAction__id'
  | 'renounceAction__block'
  | 'renounceAction__category'
  | 'renounceAction__chainId'
  | 'renounceAction__hash'
  | 'renounceAction__from'
  | 'renounceAction__subgraphId'
  | 'renounceAction__timestamp'
  | 'renounceAction__addressA'
  | 'renounceAction__addressB'
  | 'renounceAction__amountA'
  | 'renounceAction__amountB'
  | 'renounceTime'
  | 'canceled'
  | 'canceledAction'
  | 'canceledAction__id'
  | 'canceledAction__block'
  | 'canceledAction__category'
  | 'canceledAction__chainId'
  | 'canceledAction__hash'
  | 'canceledAction__from'
  | 'canceledAction__subgraphId'
  | 'canceledAction__timestamp'
  | 'canceledAction__addressA'
  | 'canceledAction__addressB'
  | 'canceledAction__amountA'
  | 'canceledAction__amountB'
  | 'canceledTime'
  | 'cliffTime'
  | 'cliffAmount'
  | 'endTime'
  | 'startTime'
  | 'duration'
  | 'transferable'
  | 'depositAmount'
  | 'intactAmount'
  | 'withdrawnAmount'
  | 'actions'
  | 'batch'
  | 'batch__id'
  | 'batch__size'
  | 'batch__label'
  | 'batch__hash'
  | 'batch__timestamp'
  | 'position'
  | 'segments'
  | 'tranches'
  | 'brokerFeeAmount'
  | 'protocolFeeAmount';

export type Tranche = {
  /** unique id resolving to stream id concatenated with the position of the tranche */
  id: Scalars['String'];
  /** position of the tranche inside the array */
  position: Scalars['BigInt'];
  /** stream upon which the tranche is used */
  stream: Stream;
  /** amount resolving to the value streamed during this tranche */
  amount: Scalars['BigInt'];
  /** timestamp resolving to the end moment of the tranche */
  timestamp: Scalars['BigInt'];
  /** timestamp resolving to the *timestamp* parameter */
  endTime: Scalars['BigInt'];
  /** timestamp resolving to the start moment of the timestamp (the end time of previous timestamp or the startTime of the stream) */
  startTime: Scalars['BigInt'];
  /** derived amount resoling to the total amount streamed until the start of the timestamp */
  startAmount: Scalars['BigInt'];
  /** derived amount resoling to the total amount streamed until the end of the timestamp */
  endAmount: Scalars['BigInt'];
};

export type Tranche_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['BigInt']>;
  position_not?: InputMaybe<Scalars['BigInt']>;
  position_gt?: InputMaybe<Scalars['BigInt']>;
  position_lt?: InputMaybe<Scalars['BigInt']>;
  position_gte?: InputMaybe<Scalars['BigInt']>;
  position_lte?: InputMaybe<Scalars['BigInt']>;
  position_in?: InputMaybe<Array<Scalars['BigInt']>>;
  position_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stream?: InputMaybe<Scalars['String']>;
  stream_not?: InputMaybe<Scalars['String']>;
  stream_gt?: InputMaybe<Scalars['String']>;
  stream_lt?: InputMaybe<Scalars['String']>;
  stream_gte?: InputMaybe<Scalars['String']>;
  stream_lte?: InputMaybe<Scalars['String']>;
  stream_in?: InputMaybe<Array<Scalars['String']>>;
  stream_not_in?: InputMaybe<Array<Scalars['String']>>;
  stream_contains?: InputMaybe<Scalars['String']>;
  stream_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_not_contains?: InputMaybe<Scalars['String']>;
  stream_not_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_starts_with?: InputMaybe<Scalars['String']>;
  stream_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_starts_with?: InputMaybe<Scalars['String']>;
  stream_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_ends_with?: InputMaybe<Scalars['String']>;
  stream_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_ends_with?: InputMaybe<Scalars['String']>;
  stream_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_?: InputMaybe<Stream_filter>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime?: InputMaybe<Scalars['BigInt']>;
  endTime_not?: InputMaybe<Scalars['BigInt']>;
  endTime_gt?: InputMaybe<Scalars['BigInt']>;
  endTime_lt?: InputMaybe<Scalars['BigInt']>;
  endTime_gte?: InputMaybe<Scalars['BigInt']>;
  endTime_lte?: InputMaybe<Scalars['BigInt']>;
  endTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime?: InputMaybe<Scalars['BigInt']>;
  startTime_not?: InputMaybe<Scalars['BigInt']>;
  startTime_gt?: InputMaybe<Scalars['BigInt']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']>;
  startTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startAmount?: InputMaybe<Scalars['BigInt']>;
  startAmount_not?: InputMaybe<Scalars['BigInt']>;
  startAmount_gt?: InputMaybe<Scalars['BigInt']>;
  startAmount_lt?: InputMaybe<Scalars['BigInt']>;
  startAmount_gte?: InputMaybe<Scalars['BigInt']>;
  startAmount_lte?: InputMaybe<Scalars['BigInt']>;
  startAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endAmount?: InputMaybe<Scalars['BigInt']>;
  endAmount_not?: InputMaybe<Scalars['BigInt']>;
  endAmount_gt?: InputMaybe<Scalars['BigInt']>;
  endAmount_lt?: InputMaybe<Scalars['BigInt']>;
  endAmount_gte?: InputMaybe<Scalars['BigInt']>;
  endAmount_lte?: InputMaybe<Scalars['BigInt']>;
  endAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Tranche_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Tranche_filter>>>;
};

export type Tranche_orderBy =
  | 'id'
  | 'position'
  | 'stream'
  | 'stream__id'
  | 'stream__alias'
  | 'stream__subgraphId'
  | 'stream__tokenId'
  | 'stream__version'
  | 'stream__category'
  | 'stream__chainId'
  | 'stream__hash'
  | 'stream__timestamp'
  | 'stream__funder'
  | 'stream__sender'
  | 'stream__recipient'
  | 'stream__proxender'
  | 'stream__proxied'
  | 'stream__cliff'
  | 'stream__cancelable'
  | 'stream__renounceTime'
  | 'stream__canceled'
  | 'stream__canceledTime'
  | 'stream__cliffTime'
  | 'stream__cliffAmount'
  | 'stream__endTime'
  | 'stream__startTime'
  | 'stream__duration'
  | 'stream__transferable'
  | 'stream__depositAmount'
  | 'stream__intactAmount'
  | 'stream__withdrawnAmount'
  | 'stream__position'
  | 'stream__brokerFeeAmount'
  | 'stream__protocolFeeAmount'
  | 'amount'
  | 'timestamp'
  | 'endTime'
  | 'startTime'
  | 'startAmount'
  | 'endAmount';

export type Watcher = {
  /** unique identifier for the watcher, resolving to the chainId - there is one watcher for entire subgraph */
  id: Scalars['String'];
  /** hardcoded chain id */
  chainId: Scalars['BigInt'];
  /** global index for streams */
  streamIndex: Scalars['BigInt'];
  /** global index for streams */
  actionIndex: Scalars['BigInt'];
  /** flag that defines the initialization status of the subgraph */
  initialized: Scalars['Boolean'];
  /** list of logs, for debugging purposes */
  logs: Array<Scalars['String']>;
};

export type Watcher_filter = {
  id?: InputMaybe<Scalars['String']>;
  id_not?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_contains_nocase?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  id_starts_with?: InputMaybe<Scalars['String']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_starts_with?: InputMaybe<Scalars['String']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id_ends_with?: InputMaybe<Scalars['String']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  id_not_ends_with?: InputMaybe<Scalars['String']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['BigInt']>;
  chainId_not?: InputMaybe<Scalars['BigInt']>;
  chainId_gt?: InputMaybe<Scalars['BigInt']>;
  chainId_lt?: InputMaybe<Scalars['BigInt']>;
  chainId_gte?: InputMaybe<Scalars['BigInt']>;
  chainId_lte?: InputMaybe<Scalars['BigInt']>;
  chainId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  chainId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  streamIndex?: InputMaybe<Scalars['BigInt']>;
  streamIndex_not?: InputMaybe<Scalars['BigInt']>;
  streamIndex_gt?: InputMaybe<Scalars['BigInt']>;
  streamIndex_lt?: InputMaybe<Scalars['BigInt']>;
  streamIndex_gte?: InputMaybe<Scalars['BigInt']>;
  streamIndex_lte?: InputMaybe<Scalars['BigInt']>;
  streamIndex_in?: InputMaybe<Array<Scalars['BigInt']>>;
  streamIndex_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  actionIndex?: InputMaybe<Scalars['BigInt']>;
  actionIndex_not?: InputMaybe<Scalars['BigInt']>;
  actionIndex_gt?: InputMaybe<Scalars['BigInt']>;
  actionIndex_lt?: InputMaybe<Scalars['BigInt']>;
  actionIndex_gte?: InputMaybe<Scalars['BigInt']>;
  actionIndex_lte?: InputMaybe<Scalars['BigInt']>;
  actionIndex_in?: InputMaybe<Array<Scalars['BigInt']>>;
  actionIndex_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialized?: InputMaybe<Scalars['Boolean']>;
  initialized_not?: InputMaybe<Scalars['Boolean']>;
  initialized_in?: InputMaybe<Array<Scalars['Boolean']>>;
  initialized_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  logs?: InputMaybe<Array<Scalars['String']>>;
  logs_not?: InputMaybe<Array<Scalars['String']>>;
  logs_contains?: InputMaybe<Array<Scalars['String']>>;
  logs_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  logs_not_contains?: InputMaybe<Array<Scalars['String']>>;
  logs_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Watcher_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Watcher_filter>>>;
};

export type Watcher_orderBy =
  | 'id'
  | 'chainId'
  | 'streamIndex'
  | 'actionIndex'
  | 'initialized'
  | 'logs';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
  Aggregation_interval: Aggregation_interval;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  DAO: ResolverTypeWrapper<DAO>;
  DAO_filter: DAO_filter;
  DAO_orderBy: DAO_orderBy;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  OrderDirection: OrderDirection;
  String: ResolverTypeWrapper<Scalars['String']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
  Action: ResolverTypeWrapper<Action>;
  ActionCategory: ActionCategory;
  Action_filter: Action_filter;
  Action_orderBy: Action_orderBy;
  Asset: ResolverTypeWrapper<Asset>;
  Asset_filter: Asset_filter;
  Asset_orderBy: Asset_orderBy;
  Batch: ResolverTypeWrapper<Batch>;
  Batch_filter: Batch_filter;
  Batch_orderBy: Batch_orderBy;
  Batcher: ResolverTypeWrapper<Batcher>;
  Batcher_filter: Batcher_filter;
  Batcher_orderBy: Batcher_orderBy;
  Contract: ResolverTypeWrapper<Contract>;
  ContractCategory: ContractCategory;
  Contract_filter: Contract_filter;
  Contract_orderBy: Contract_orderBy;
  Segment: ResolverTypeWrapper<Segment>;
  Segment_filter: Segment_filter;
  Segment_orderBy: Segment_orderBy;
  Stream: ResolverTypeWrapper<Stream>;
  StreamCategory: StreamCategory;
  Stream_filter: Stream_filter;
  Stream_orderBy: Stream_orderBy;
  Tranche: ResolverTypeWrapper<Tranche>;
  Tranche_filter: Tranche_filter;
  Tranche_orderBy: Tranche_orderBy;
  Watcher: ResolverTypeWrapper<Watcher>;
  Watcher_filter: Watcher_filter;
  Watcher_orderBy: Watcher_orderBy;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Subscription: {};
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Bytes: Scalars['Bytes'];
  DAO: DAO;
  DAO_filter: DAO_filter;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  String: Scalars['String'];
  Timestamp: Scalars['Timestamp'];
  _Block_: _Block_;
  _Meta_: _Meta_;
  Action: Action;
  Action_filter: Action_filter;
  Asset: Asset;
  Asset_filter: Asset_filter;
  Batch: Batch;
  Batch_filter: Batch_filter;
  Batcher: Batcher;
  Batcher_filter: Batcher_filter;
  Contract: Contract;
  Contract_filter: Contract_filter;
  Segment: Segment;
  Segment_filter: Segment_filter;
  Stream: Stream;
  Stream_filter: Stream_filter;
  Tranche: Tranche;
  Tranche_filter: Tranche_filter;
  Watcher: Watcher;
  Watcher_filter: Watcher_filter;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  dao?: Resolver<Maybe<ResolversTypes['DAO']>, ParentType, ContextType, RequireFields<QuerydaoArgs, 'id' | 'subgraphError'>>;
  daos?: Resolver<Array<ResolversTypes['DAO']>, ParentType, ContextType, RequireFields<QuerydaosArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType, RequireFields<QueryactionArgs, 'id' | 'subgraphError'>>;
  actions?: Resolver<Array<ResolversTypes['Action']>, ParentType, ContextType, RequireFields<QueryactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  asset?: Resolver<Maybe<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<QueryassetArgs, 'id' | 'subgraphError'>>;
  assets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<QueryassetsArgs, 'skip' | 'first' | 'subgraphError'>>;
  batch?: Resolver<Maybe<ResolversTypes['Batch']>, ParentType, ContextType, RequireFields<QuerybatchArgs, 'id' | 'subgraphError'>>;
  batches?: Resolver<Array<ResolversTypes['Batch']>, ParentType, ContextType, RequireFields<QuerybatchesArgs, 'skip' | 'first' | 'subgraphError'>>;
  batcher?: Resolver<Maybe<ResolversTypes['Batcher']>, ParentType, ContextType, RequireFields<QuerybatcherArgs, 'id' | 'subgraphError'>>;
  batchers?: Resolver<Array<ResolversTypes['Batcher']>, ParentType, ContextType, RequireFields<QuerybatchersArgs, 'skip' | 'first' | 'subgraphError'>>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<QuerycontractArgs, 'id' | 'subgraphError'>>;
  contracts?: Resolver<Array<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<QuerycontractsArgs, 'skip' | 'first' | 'subgraphError'>>;
  segment?: Resolver<Maybe<ResolversTypes['Segment']>, ParentType, ContextType, RequireFields<QuerysegmentArgs, 'id' | 'subgraphError'>>;
  segments?: Resolver<Array<ResolversTypes['Segment']>, ParentType, ContextType, RequireFields<QuerysegmentsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<QuerystreamArgs, 'id' | 'subgraphError'>>;
  streams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<QuerystreamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tranche?: Resolver<Maybe<ResolversTypes['Tranche']>, ParentType, ContextType, RequireFields<QuerytrancheArgs, 'id' | 'subgraphError'>>;
  tranches?: Resolver<Array<ResolversTypes['Tranche']>, ParentType, ContextType, RequireFields<QuerytranchesArgs, 'skip' | 'first' | 'subgraphError'>>;
  watcher?: Resolver<Maybe<ResolversTypes['Watcher']>, ParentType, ContextType, RequireFields<QuerywatcherArgs, 'id' | 'subgraphError'>>;
  watchers?: Resolver<Array<ResolversTypes['Watcher']>, ParentType, ContextType, RequireFields<QuerywatchersArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  dao?: SubscriptionResolver<Maybe<ResolversTypes['DAO']>, "dao", ParentType, ContextType, RequireFields<SubscriptiondaoArgs, 'id' | 'subgraphError'>>;
  daos?: SubscriptionResolver<Array<ResolversTypes['DAO']>, "daos", ParentType, ContextType, RequireFields<SubscriptiondaosArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
  action?: SubscriptionResolver<Maybe<ResolversTypes['Action']>, "action", ParentType, ContextType, RequireFields<SubscriptionactionArgs, 'id' | 'subgraphError'>>;
  actions?: SubscriptionResolver<Array<ResolversTypes['Action']>, "actions", ParentType, ContextType, RequireFields<SubscriptionactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  asset?: SubscriptionResolver<Maybe<ResolversTypes['Asset']>, "asset", ParentType, ContextType, RequireFields<SubscriptionassetArgs, 'id' | 'subgraphError'>>;
  assets?: SubscriptionResolver<Array<ResolversTypes['Asset']>, "assets", ParentType, ContextType, RequireFields<SubscriptionassetsArgs, 'skip' | 'first' | 'subgraphError'>>;
  batch?: SubscriptionResolver<Maybe<ResolversTypes['Batch']>, "batch", ParentType, ContextType, RequireFields<SubscriptionbatchArgs, 'id' | 'subgraphError'>>;
  batches?: SubscriptionResolver<Array<ResolversTypes['Batch']>, "batches", ParentType, ContextType, RequireFields<SubscriptionbatchesArgs, 'skip' | 'first' | 'subgraphError'>>;
  batcher?: SubscriptionResolver<Maybe<ResolversTypes['Batcher']>, "batcher", ParentType, ContextType, RequireFields<SubscriptionbatcherArgs, 'id' | 'subgraphError'>>;
  batchers?: SubscriptionResolver<Array<ResolversTypes['Batcher']>, "batchers", ParentType, ContextType, RequireFields<SubscriptionbatchersArgs, 'skip' | 'first' | 'subgraphError'>>;
  contract?: SubscriptionResolver<Maybe<ResolversTypes['Contract']>, "contract", ParentType, ContextType, RequireFields<SubscriptioncontractArgs, 'id' | 'subgraphError'>>;
  contracts?: SubscriptionResolver<Array<ResolversTypes['Contract']>, "contracts", ParentType, ContextType, RequireFields<SubscriptioncontractsArgs, 'skip' | 'first' | 'subgraphError'>>;
  segment?: SubscriptionResolver<Maybe<ResolversTypes['Segment']>, "segment", ParentType, ContextType, RequireFields<SubscriptionsegmentArgs, 'id' | 'subgraphError'>>;
  segments?: SubscriptionResolver<Array<ResolversTypes['Segment']>, "segments", ParentType, ContextType, RequireFields<SubscriptionsegmentsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stream?: SubscriptionResolver<Maybe<ResolversTypes['Stream']>, "stream", ParentType, ContextType, RequireFields<SubscriptionstreamArgs, 'id' | 'subgraphError'>>;
  streams?: SubscriptionResolver<Array<ResolversTypes['Stream']>, "streams", ParentType, ContextType, RequireFields<SubscriptionstreamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tranche?: SubscriptionResolver<Maybe<ResolversTypes['Tranche']>, "tranche", ParentType, ContextType, RequireFields<SubscriptiontrancheArgs, 'id' | 'subgraphError'>>;
  tranches?: SubscriptionResolver<Array<ResolversTypes['Tranche']>, "tranches", ParentType, ContextType, RequireFields<SubscriptiontranchesArgs, 'skip' | 'first' | 'subgraphError'>>;
  watcher?: SubscriptionResolver<Maybe<ResolversTypes['Watcher']>, "watcher", ParentType, ContextType, RequireFields<SubscriptionwatcherArgs, 'id' | 'subgraphError'>>;
  watchers?: SubscriptionResolver<Array<ResolversTypes['Watcher']>, "watchers", ParentType, ContextType, RequireFields<SubscriptionwatchersArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type DAOResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['DAO'] = ResolversParentTypes['DAO']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  parentAddress?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hierarchy?: Resolver<Array<ResolversTypes['DAO']>, ParentType, ContextType, RequireFields<DAOhierarchyArgs, 'skip' | 'first'>>;
  proposalTemplatesHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snapshotENS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentHash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Action'] = ResolversParentTypes['Action']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['ActionCategory'], ParentType, ContextType>;
  chainId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType>;
  subgraphId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  addressA?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  addressB?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  amountA?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  amountB?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssetResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  chainId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<AssetstreamsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BatchResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Batch'] = ResolversParentTypes['Batch']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  batcher?: Resolver<Maybe<ResolversTypes['Batcher']>, ParentType, ContextType>;
  streams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<BatchstreamsArgs, 'skip' | 'first'>>;
  hash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BatcherResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Batcher'] = ResolversParentTypes['Batcher']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  batchIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  batches?: Resolver<Array<ResolversTypes['Batch']>, ParentType, ContextType, RequireFields<BatcherbatchesArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContractResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  admin?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['ContractCategory'], ParentType, ContextType>;
  streams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<ContractstreamsArgs, 'skip' | 'first'>>;
  actions?: Resolver<Array<ResolversTypes['Action']>, ParentType, ContextType, RequireFields<ContractactionsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SegmentResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Segment'] = ResolversParentTypes['Segment']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  stream?: Resolver<ResolversTypes['Stream'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  exponent?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  milestone?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StreamResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Stream'] = ResolversParentTypes['Stream']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subgraphId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['StreamCategory'], ParentType, ContextType>;
  chainId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  funder?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  parties?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  proxender?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  proxied?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cliff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  asset?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  cancelable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  renounceAction?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  renounceTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  canceled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canceledAction?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  canceledTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  cliffTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  cliffAmount?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transferable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  depositAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  intactAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  withdrawnAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['Action']>, ParentType, ContextType, RequireFields<StreamactionsArgs, 'skip' | 'first'>>;
  batch?: Resolver<ResolversTypes['Batch'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  segments?: Resolver<Array<ResolversTypes['Segment']>, ParentType, ContextType, RequireFields<StreamsegmentsArgs, 'skip' | 'first'>>;
  tranches?: Resolver<Array<ResolversTypes['Tranche']>, ParentType, ContextType, RequireFields<StreamtranchesArgs, 'skip' | 'first'>>;
  brokerFeeAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  protocolFeeAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrancheResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Tranche'] = ResolversParentTypes['Tranche']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  stream?: Resolver<ResolversTypes['Stream'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WatcherResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Watcher'] = ResolversParentTypes['Watcher']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chainId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  streamIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  actionIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  initialized?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logs?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Query?: QueryResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Bytes?: GraphQLScalarType;
  DAO?: DAOResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Timestamp?: GraphQLScalarType;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
  Action?: ActionResolvers<ContextType>;
  Asset?: AssetResolvers<ContextType>;
  Batch?: BatchResolvers<ContextType>;
  Batcher?: BatcherResolvers<ContextType>;
  Contract?: ContractResolvers<ContextType>;
  Segment?: SegmentResolvers<ContextType>;
  Stream?: StreamResolvers<ContextType>;
  Tranche?: TrancheResolvers<ContextType>;
  Watcher?: WatcherResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = DecentTypes.Context & SablierTypes.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/decent/introspectionSchema":
      return import("./sources/decent/introspectionSchema") as T;
    
    case ".graphclient/sources/sablier/introspectionSchema":
      return import("./sources/sablier/introspectionSchema") as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const decentTransforms = [];
const sablierTransforms = [];
const additionalTypeDefs = [] as any[];
const decentHandler = new GraphqlHandler({
              name: "decent",
              config: {"endpoint":"https://api.studio.thegraph.com/query/{context.subgraphSpace:71032}/{context.subgraphSlug:fractal-mainnet}/{context.subgraphVersion:v0.1.1}"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("decent"),
              logger: logger.child("decent"),
              importFn,
            });
const sablierHandler = new GraphqlHandler({
              name: "sablier",
              config: {"endpoint":"https://api.studio.thegraph.com/query/{context.subgraphSpace:57079}/{context.subgraphSlug:sablier-v2}/version/latest"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("sablier"),
              logger: logger.child("sablier"),
              importFn,
            });
sources[0] = {
          name: 'decent',
          handler: decentHandler,
          transforms: decentTransforms
        }
sources[1] = {
          name: 'sablier',
          handler: sablierHandler,
          transforms: sablierTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(StitchingMerger as any)({
        cache,
        pubsub,
        logger: logger.child('stitchingMerger'),
        store: rootStore.child('stitchingMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: DaoQueryDocument,
        get rawSDL() {
          return printWithCache(DaoQueryDocument);
        },
        location: 'DaoQueryDocument.graphql'
      },{
        document: StreamsQueryDocument,
        get rawSDL() {
          return printWithCache(StreamsQueryDocument);
        },
        location: 'StreamsQueryDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type DAOQueryQueryVariables = Exact<{
  daoAddress?: InputMaybe<Scalars['Bytes']>;
}>;


export type DAOQueryQuery = { daos: Array<(
    Pick<DAO, 'id' | 'address' | 'parentAddress' | 'name' | 'snapshotENS' | 'proposalTemplatesHash'>
    & { hierarchy: Array<(
      Pick<DAO, 'id' | 'address' | 'parentAddress' | 'name' | 'snapshotENS'>
      & { hierarchy: Array<(
        Pick<DAO, 'id' | 'address' | 'parentAddress' | 'name' | 'snapshotENS'>
        & { hierarchy: Array<(
          Pick<DAO, 'id' | 'address' | 'parentAddress' | 'name' | 'snapshotENS'>
          & { hierarchy: Array<Pick<DAO, 'id' | 'address' | 'parentAddress' | 'name' | 'snapshotENS'>> }
        )> }
      )> }
    )> }
  )> };

export type StreamsQueryQueryVariables = Exact<{
  recipientAddress?: InputMaybe<Scalars['Bytes']>;
}>;


export type StreamsQueryQuery = { streams: Array<(
    Pick<Stream, 'id' | 'startTime' | 'endTime' | 'canceled' | 'duration' | 'category' | 'cliff' | 'cliffTime' | 'cliffAmount' | 'depositAmount' | 'withdrawnAmount' | 'intactAmount' | 'chainId' | 'recipient' | 'parties' | 'timestamp'>
    & { contract: Pick<Contract, 'address' | 'id'>, segments: Array<Pick<Segment, 'id' | 'startTime' | 'endTime' | 'amount' | 'endAmount' | 'position'>>, tranches: Array<Pick<Tranche, 'id' | 'position' | 'startTime' | 'startAmount' | 'amount' | 'endTime' | 'endAmount'>>, asset: Pick<Asset, 'name' | 'symbol' | 'address' | 'id' | 'chainId' | 'decimals'> }
  )> };


export const DAOQueryDocument = gql`
    query DAOQuery($daoAddress: Bytes) {
  daos(where: {id: $daoAddress}) {
    id
    address
    parentAddress
    name
    snapshotENS
    hierarchy {
      id
      address
      parentAddress
      name
      snapshotENS
      hierarchy {
        id
        address
        parentAddress
        name
        snapshotENS
        hierarchy {
          id
          address
          parentAddress
          name
          snapshotENS
          hierarchy {
            id
            address
            parentAddress
            name
            snapshotENS
          }
        }
      }
    }
    proposalTemplatesHash
  }
}
    ` as unknown as DocumentNode<DAOQueryQuery, DAOQueryQueryVariables>;
export const StreamsQueryDocument = gql`
    query StreamsQuery($recipientAddress: Bytes) {
  streams(where: {recipient: $recipientAddress}) {
    id
    startTime
    endTime
    canceled
    duration
    category
    cliff
    cliffTime
    cliffAmount
    depositAmount
    withdrawnAmount
    intactAmount
    chainId
    recipient
    parties
    contract {
      address
      id
    }
    timestamp
    segments {
      id
      startTime
      endTime
      amount
      endAmount
      position
    }
    tranches {
      id
      position
      startTime
      startAmount
      amount
      endTime
      endAmount
    }
    asset {
      name
      symbol
      address
      id
      chainId
      decimals
    }
  }
}
    ` as unknown as DocumentNode<StreamsQueryQuery, StreamsQueryQueryVariables>;



export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    DAOQuery(variables?: DAOQueryQueryVariables, options?: C): Promise<DAOQueryQuery> {
      return requester<DAOQueryQuery, DAOQueryQueryVariables>(DAOQueryDocument, variables, options) as Promise<DAOQueryQuery>;
    },
    StreamsQuery(variables?: StreamsQueryQueryVariables, options?: C): Promise<StreamsQueryQuery> {
      return requester<StreamsQueryQuery, StreamsQueryQueryVariables>(StreamsQueryDocument, variables, options) as Promise<StreamsQueryQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;